<template>
  <div>
    <component :is="layout">
      <div v-if="notifiers" class="card shadow text-center p-2 m-2">
        <h3 class="pb-2">Update Notifier</h3>
        <span v-if="message">
          <p class="text-center alert-success">{{ message }}</p>
        </span>
        <div class="from-group">
          <label for="marquee"> Marquee </label>
          <ejs-richtexteditor
            ref="rte_instance"
            id="rte2"
            v-model="notifiers.marquee"
          ></ejs-richtexteditor>
        </div>
        <div class="from-group">
          <label for="notification"> Notification </label>
          <ejs-richtexteditor
            ref="rte_instance"
            id="rte1"
            v-model="notifiers.notification"
          ></ejs-richtexteditor>
        </div>

        <div class="from-group">
          <label for="footer"> Footer </label>
          <textarea
            v-model="notifiers.footer"
            type="text"
            class="form-control"
          ></textarea>
        </div>
        <div class="from-group">
          <label for="footer">Whatsapp Community </label>
          <input
            v-model="notifiers.whatsapp_community"
            type="text"
            class="form-control"
          />
        </div>
        <div class="from-group">
          <label for="footer"> Cricket Game </label>
          <input
            v-model="notifiers.cricket_game"
            type="text"
            class="form-control"
          />
        </div>
        <div class="from-group">
          <label for="footer"> Online Game </label>
          <input
            v-model="notifiers.online_game"
            type="text"
            class="form-control"
          />
        </div>
        <div class="mt-3 from-group text-white">
          <label for="image">Image</label>
          <input
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            id="file-input"
          />
        </div>

        <div class="mt-3 from-group">
          <button @click="removeImage()" class="btn btn-danger">
            Remove Image
          </button>
        </div>

        <div class="form-group py-4">
          <button
            @click="updateNotifier()"
            class="btn btn-block w-100 bg-primary btn-primary"
          >
            Update
          </button>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import axiosObject from "../../axiosHandler";
import axiosConfig from "../../axiosConfig";
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Image,
  HtmlEditor,
} from "@syncfusion/ej2-vue-richtexteditor";
export default {
  provide: {
    richtexteditor: [Toolbar, Link, Image, HtmlEditor],
  },
  components: {
    "ejs-richtexteditor": RichTextEditorComponent,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      notifiers: null,
      message: null,
    };
  },
  mounted() {
    // Simulate the dynamic loading of content
    // In a real scenario, this could be an API call or some asynchronous operation
    setTimeout(() => {
      // Call the method to hide the div after the content is loaded
      this.hideDynamicallyLoadedDiv();
    }, 2000); // Adjust the timeout as needed
  },
  created() {
    this.fetchNotifier();
  },
  methods: {
    fetchNotifier() {
      axiosObject.get("/api/admin/notifier", axiosConfig).then(
        function (response) {
          this.notifiers = response.data.notifiers;
        }.bind(this)
      );
    },
    updateNotifier() {
      let data = new FormData();
      data.append("marquee", this.notifiers.marquee);
      data.append("notification", this.notifiers.notification);

      if (
        this.notifiers.whatsapp_community !== null &&
        this.notifiers.whatsapp_community !== undefined
      ) {
        data.append("whatsapp_community", this.notifiers.whatsapp_community);
      }

      if (
        this.notifiers.cricket_game !== null &&
        this.notifiers.cricket_game !== undefined
      ) {
        data.append("cricket_game", this.notifiers.cricket_game);
      }

      if (
        this.notifiers.online_game !== null &&
        this.notifiers.online_game !== undefined
      ) {
        data.append("online_game", this.notifiers.online_game);
      }
      data.append("footer", this.notifiers.footer);
      data.append("image", this.notifiers.image);
      axiosObject
        .post("/api/admin/notifier", data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(
          function (response) {
            this.message = response.data.message;
            this.fetchNotifier();
          }.bind(this)
        );
    },
    uploadImage(event) {
      this.notifiers["image"] = event.target.files[0];
      console.log(this.notifiers);
    },
    removeImage() {
      axiosObject
        .post("/api/admin/notifier/remove_image", axiosConfig)
        .then((res) => {
          this.message = res.data.message;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    hideDynamicallyLoadedDiv() {
      // Select the div with the specified styles
      var fixedDiv = document.querySelector('div[style*="position: fixed;"]');

      // Check if the div is found
      if (fixedDiv) {
        // Set the display property to "none" to hide the div
        fixedDiv.style.display = "none";
        console.log("Div hidden");
      } else {
        console.log("No matching div found");
      }
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>
